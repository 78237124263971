export default class ModifySwsModerateDialogViewModel {
  private STEPS = [
    'ModifySwsModerate',
    'ModifySwsModerateWealthExitPoll',
  ];

  current_step = 0;

  current_component = this.STEPS[this.current_step];

  show_calendly_dialog = false;

  is_loading = false;

  prevStep = () => {
    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  nextStep = () => {
    if (this.current_step < this.STEPS.length - 1) {
      this.current_step += 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  endProcess = () => {
    this.current_step = 0;
    this.current_component = this.STEPS[this.current_step];
  }

  scheduleCall = () => {
    this.endProcess();
    this.show_calendly_dialog = true;
  }

  setLoading = (is_loading: boolean) => {
    this.is_loading = is_loading;
  }
}
