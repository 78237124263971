

































import { Component, PropSync, Vue } from 'vue-property-decorator';
import ModifySwsModerateDialogViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/modify-sws-moderate/modify-sws-moderate-dialog-view-model';

@Component({
  name: 'ModifySwsModerateDialog',
  components: {
    ModifySwsModerate: () => import('@/vue-app/components/strategy-moderate-wealth/modify-sws-moderate/ModifySwsModerate.vue'),
    ModifySwsModerateWealthExitPoll: () => import('@/vue-app/components/strategy-moderate-wealth/modify-sws-moderate/ModifySwsModerateWealthExitPoll.vue'),
  },
})
export default class ModifySwsModerateDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('investorGoalId', { type: String, required: true })
  synced_investor_goal_id!: string;

  modify_sws_moderate_dialog_model = Vue.observable(new ModifySwsModerateDialogViewModel());

  closeModal() {
    this.synced_is_open = false;
  }

  endProcess() {
    this.$emit('showUnlinkStatus');
    this.modify_sws_moderate_dialog_model.endProcess();
    this.synced_is_open = false;
  }

  scheduleCall() {
    this.modify_sws_moderate_dialog_model.scheduleCall();
    this.synced_is_open = false;
  }
}
